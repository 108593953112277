// 미세먼지 
import { finedust } from './index';

const fineDustQ = (reqData) => {
    return finedust.get(`finedust?searchCondition=DAILY`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
}


export { fineDustQ };
