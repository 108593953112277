// content
import { content } from './index';

const contentQ = reqData => {
  return content
    .get(
      `${reqData.job}?page=${reqData.page}&npp=${reqData.npp}&fromDay=${reqData.fromDay}&toDay=${reqData.toDay}&searchGbn=${reqData.searchGbn}&searchVal=${reqData.searchVal}`
    )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export { contentQ };
