<template>
  <div class="focus">
    <Focusmark />
    <Focusbanner />
  </div>
</template>

<script>
import Focusmark from '@/components/main/safetyinfo/focusarea/FocusArea_Mark.vue';
import Focusbanner from '@/components/main/safetyinfo/focusarea/FocusArea_Banner.vue';
export default {
  components: {
    Focusmark,
    Focusbanner,
  },
};
</script>

<style>
</style>