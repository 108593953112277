<template>
  <v-tabs v-model="tab" show-arrows dark>
    <v-tab v-for="item in items" :key="item.tab" @click="PageTo(item.tab)">
      {{ item.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data() {
    return {
      selectedUrl: '',
      tab: null,
      items: [
        { key: 0, tab: 'main', text: 'MAIN LOBBY' },
        { key: 1, tab: 'betadine', text: 'Family Health & Self Care' },
        { key: 2, tab: 'tachosil', text: 'Surgery & Patient Recovery' },
        { key: 3, tab: 'meronem', text: 'Overall Wellness' },
        { key: 4, tab: 'seroquel', text: 'Mental Health ' },
        { key: 5, tab: 'ferinject', text: 'Women’s health' },
      ],
    };
  },
  created() {
    const path = this.$route.path;
    this.selectedUrl = path.split('/')[1];
    console.log(this.selectedUrl);
    setTimeout(() => {
      // menu item highlight
      for (const item of this.items) {
        if (this.selectedUrl === item.tab) {
          //   console.log(item.key);
          this.tab = item.key;
        }
      }
    }, 50);
  },
  methods: {
    PageTo(url) {
      //   console.log(url);

      if (this.selectedUrl === url) {
        console.log('같은페이지 인경우 리플래시 처리');
        return;
      }
      this.selectedUrl = url;

      switch (url) {
        case 'main':
          this.$router.push(this.$url_list.MAIN);
          break;
        case 'betadine':
          this.$router.push(this.$url_list.BETADINE);
          break;
        case 'tachosil':
          this.$router.push(this.$url_list.TACHOSIL);
          break;
        case 'meronem':
          this.$router.push(this.$url_list.MERONEM);
          break;
        case 'seroquel':
          this.$router.push(this.$url_list.SEROQUEL);
          break;
        case 'ferinject':
          this.$router.push(this.$url_list.FERINJECT);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.v-tabs {
  position: fixed;
  z-index: 200;
  .v-item-group {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}
</style>