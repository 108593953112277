<template>
  <div class="radar">
    <div class="r1"></div>
    <div class="r2"></div>
    <div class="r3"></div>
    <div class="r4"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.radar {
  position: relative;
  height: 100%;
  // overflow: hidden;
  > div {
    position: absolute;
    background: radial-gradient(rgba(111, 161, 184, 0.1), rgba(111, 161, 184, 0.15));
    border-radius: 100%;
    color: #fff;
    animation: pluse 3s linear infinite;
    width: 250px;
    height: 250px;
  }
  .r1 {
    top: 120px;
    right: 410px;
  }
  .r2 {
    top: 380px;
    left: 100px;
  }
  .r3 {
    top: 480px;
    right: 280px;
  }
  .r4 {
    bottom: 470px;
    left: 100px;
  }
}
@keyframes pluse {
  0% {
    box-shadow: 0 0 0 0 rgba(111, 161, 184, 0.2), 0 0 0 0 rgba(111, 161, 184, 0.2);
  }
  40% {
    box-shadow: 0 0 0 50px rgba(9, 13, 16, 0), 0 0 0 0 rgba(111, 161, 184, 0.2);
  }
  80% {
    box-shadow: 0 0 0 50px rgba(9, 13, 16, 0), 0 0 0 40px rgba(9, 13, 16, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(9, 13, 16, 0), 0 0 0 40px rgba(9, 13, 16, 0);
  }
}
</style>