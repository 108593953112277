<template>
  <div v-bind:style="bottomLegend" class="bottom-legend">
    <li v-bind:style="li" class="sos" :class="{ active: active === 1 }">CCTV/비상벨</li>
    <li v-bind:style="li" class="safety-board" :class="{ active: active === 2 }">안심 게시판</li>
    <li v-bind:style="li" class="focus-area" :class="{ active: active === 3 }">집중 관찰 구간</li>
    <li v-bind:style="li" class="smart-light" :class="{ active: active === 4 }">스마트 보안등</li>
    <li v-bind:style="li" class="safe-wayhome" :class="{ active: active === 5 }">여성 안심 귀갓길</li>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  props: {
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      scale: 0,
      bottomLegend: {
        bottom: '',
        paddingLeft: '',
        paddingRight: '',
      },
      li: {
        paddingBottom: '',
        fontSize: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  mounted() {
    // console.log("ready...");
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log(window.innerWidth);

      this.bottomLegend.bottom = this.scale * 350 + 'px';
      this.bottomLegend.paddingLeft = this.scale * 40 + 'px';
      this.bottomLegend.paddingRight = this.scale * 40 + 'px';

      // this.li.paddingBottom = this.scale * 5 + 'px';
      // this.li.fontSize = this.scale * 16 + 'px';

      // li & li::before style
      $('.bottom-legend li').attr(
        'style',
        `padding-bottom: ${this.scale * 5}px; font-size: ${this.scale * 16}px; --width:${this.scale * 14}px; --height:${
          this.scale * 14
        }px; --margin-right:${this.scale * 7}px;`
      );
    },
  },
};
</script>

<style lang="scss">
.bottom-legend {
  position: fixed;
  bottom: 350px;
  padding: 0 40px;
  li {
    list-style: none;
    padding-bottom: 5px;
    font-size: 16px;
    color: #717b7c;
    font-weight: 600;
    transition: 0.4s;
    &.active {
      font-weight: 900;
      color: #d8e3e5;
    }
    &:before {
      content: '';
      display: inline-block;
      // width: 14px;
      // height: 14px;
      border-radius: 7px;
      // margin-right: 7px;
      width: var(--width, 14px);
      height: var(--height, 14px);
      margin-right: var(--margin-right, 7px);
    }
    &.sos:before {
      background: #e79b24;
    }
    &.safety-board:before {
      background: #ced252;
    }
    &.focus-area:before {
      background: #6dc4de;
    }
    &.smart-light:before {
      background: #20b785;
    }
    &.safe-wayhome:before {
      background: #e56a6a;
    }
  }
}
</style>