<template>
  <div v-bind:style="safetyInfo" class="safety-info">
    <div class="map">
      <img class="load" src="@/assets/img/map/load.png" alt="" />
      <transition name="fade">
        <img v-if="active === 2" class="landmark" src="@/assets/img/map/landmark_sb.png" alt="" />
      </transition>
      <transition name="fade">
        <img v-if="active !== 2" class="landmark" src="@/assets/img/map/landmark.png" alt="" />
      </transition>
      <div v-bind:style="shadowTop" class="shadow-top"></div>
      <div v-bind:style="shadowBottom" class="shadow-bottom"></div>

      <!-- <Radar /> -->
      <!-- <Pluse/> -->

      <!-- 순찰 거점 설명 -->
      <Patroldescription />

      <!-- SOS -->
      <Sos v-if="active === 1" />

      <!-- 안심게시판 -->
      <Safetyboard v-if="active === 2" />

      <!-- 집중관찰구간 -->
      <Focusarea v-if="active === 3" />

      <!-- 스마트보안등 -->
      <Smartlight v-if="active === 4" />

      <!-- 여성안심귀갓길 -->
      <Safewayhome v-if="active === 5" />

      <!-- 범례 -->
      <Bottomlegend :active="active" />
    </div>
  </div>
</template>

<script>
import Sos from '@/components/main/safetyinfo/sos/SOS_Start.vue';
import Safetyboard from '@/components/main/safetyinfo/safetyboard/SafetyBoard_Start.vue';
import Focusarea from '@/components/main/safetyinfo/focusarea/FocusArea_Start.vue';
import Smartlight from '@/components/main/safetyinfo/smartlight/SmartLight_Start.vue';
import Safewayhome from '@/components/main/safetyinfo/safewayhome/SafeWayhome_Start.vue';
import Patroldescription from '@/components/main/safetyinfo/PatrolArea_Description.vue';
import Bottomlegend from '@/components/main/safetyinfo/Bottom_Legend.vue';
import Radar from '@/components/main/safetyinfo/Radar.vue';
import Pluse from '@/components/main/safetyinfo/Pluse.vue';
export default {
  components: {
    Sos,
    Safetyboard,
    Focusarea,
    Smartlight,
    Safewayhome,
    Patroldescription,
    Bottomlegend,
    Radar,
    Pluse,
  },
  data() {
    return {
      timer: '',
      active: 1,

      scale: 0,
      safetyInfo: {
        height: '',
      },
      shadowTop: {
        height: '',
      },
      shadowBottom: {
        height: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.timer = setInterval(this.loopInfo, 15000);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    clearInterval(this.timer);
  },
  methods: {
    loopInfo() {
      if (this.active === 5) {
        clearInterval(this.timer);
        // alert('정지');
        this.$emit('toPoliceContent');
        return;
      }
      this.active++;
    },
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log(window.innerWidth);

      this.safetyInfo.height = this.scale * 1570 + 'px';
      this.shadowTop.height = this.scale * 260 + 'px';
      this.shadowBottom.height = this.scale * 550 + 'px';
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/style';
.safety-info {
  position: relative;
  height: calc(100% - 338px);
  background: #090d10;
  .map {
    height: 100%;
    .load {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .landmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // img {
      //   width: 500px;
      // }
    }
  }
  .shadow-top {
    position: absolute;
    width: 100%;
    height: 260px;
    background: linear-gradient(180deg, #090d10, transparent);
  }
  .shadow-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 550px;
    background: linear-gradient(0deg, #090d10 20%, transparent);
  }
}
</style>