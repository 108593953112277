<template>
  <div class="safetyboard-mark">
    <transition name="alert-in">
      <img v-bind:style="p01" v-if="animation >= 1" class="pin p01" src="@/assets/img/map/safetyboard_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p02" v-if="animation >= 2" class="pin p02" src="@/assets/img/map/safetyboard_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p03" v-if="animation >= 3" class="pin p03" src="@/assets/img/map/safetyboard_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p04" v-if="animation >= 4" class="pin p04" src="@/assets/img/map/safetyboard2_pin.png" alt="" />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animation: 0,
      scale: 0,
      p01: {
        width: '',
        top: '',
        left: '',
      },
      p02: {
        width: '',
        top: '',
        left: '',
      },
      p03: {
        width: '',
        top: '',
        left: '',
      },
      p04: {
        width: '',
        top: '',
        left: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.timer = setInterval(this.fadeInPin, 250);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    fadeInPin() {
      if (this.animation === 4) {
        clearInterval(this.timer);
        // alert('정지');
        return;
      }
      this.animation++;
    },
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log(window.innerWidth);

      this.p01.width = this.scale * 90 + 'px';
      this.p01.top = this.scale * 525 + 'px';
      this.p01.left = this.scale * 184 + 'px';

      this.p02.width = this.scale * 90 + 'px';
      this.p02.top = this.scale * 858 + 'px';
      this.p02.left = this.scale * 204 + 'px';

      this.p03.width = this.scale * 90 + 'px';
      this.p03.top = this.scale * 144 + 'px';
      this.p03.left = this.scale * 511 + 'px';

      this.p04.width = this.scale * 90 + 'px';
      this.p04.top = this.scale * 481 + 'px';
      this.p04.left = this.scale * 613 + 'px';
    },
  },
};
</script>

<style lang="scss">
.safetyboard-mark {
  .pin {
    position: absolute;
    width: 90px;
    &.p01 {
      top: 525px;
      left: 184px;
    }
    &.p02 {
      top: 858px;
      left: 204px;
    }
    &.p03 {
      top: 144px;
      left: 511px;
    }
    &.p04 {
      top: 481px;
      left: 613px;
    }
  }
  .alert-in-enter-active {
    animation: bounce-in 0.8s;
  }
  .alert-in-leave-active {
    animation: bounce-in 0.8s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>