<template>
  <!-- 일반정보 -->
  <div v-bind:style="commonInfo" class="common-info">
    <v-row>
      <v-col cols="6" v-bind:style="col">
        <div>
          <b>{{ toTime }}</b>
        </div>
        <div>
          <span v-bind:style="span">{{ today }}</span>
        </div>
      </v-col>
      <v-col cols="6" class="weather" v-bind:style="col">
        <div>
          <b>{{ weatherTotal }} {{ tmpVal }}°C</b>
        </div>
        <div>
          <span v-bind:style="span">
            미세먼지
            <!-- 0-30 -->
            <span v-bind:style="span" v-if="dustVal === 0 || (dustVal > 0 && dustVal < 31)">
              <img v-bind:style="dustIcon" src="@/assets/img/common_info/icon_good.png" alt="" />
              좋음
            </span>

            <!-- 31-80 -->
            <span v-bind:style="span" v-if="dustVal > 30 && dustVal < 81">
              <img v-bind:style="dustIcon" src="@/assets/img/common_info/icon_normal.png" alt="" />
              보통
            </span>

            <!-- 81-150 -->
            <span v-bind:style="span" v-if="dustVal > 80 && dustVal < 151">
              <img v-bind:style="dustIcon" src="@/assets/img/common_info/icon_bad.png" alt="" />
              나쁨
            </span>

            <!-- 151~ -->
            <span v-bind:style="span" v-if="dustVal > 150">
              <img v-bind:style="dustIcon" src="@/assets/img/common_info/icon_worst.png" alt="" />
              매우 나쁨
            </span>
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fineDustQ } from '@/api/finedust';
import { weatherQ } from '@/api/weather';
export default {
  data() {
    return {
      timer: null,
      today: '',
      toTime: '',
      toAmPm: '',
      dustVal: 0,
      tmpVal: '',
      weatherTotal: '',
      scale: 0,
      commonInfo: {
        height: '',
      },
      col: {
        padding: '',
        fontSize: '',
        lineHeight: '',
      },
      span: {
        fontSize: '',
      },
      dustIcon: {
        width: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  mounted() {
    // const week = this.$helper.weekLal(this.$moment().day());
    // this.today = this.$moment().format('MM월 DD일 ') + ' ' + week;
    this.timer = setInterval(() => {
      const HH = this.$helper.amPmLal(this.$moment().format('HH'));
      const HHdd = this.$moment().format('LT');
      this.toAmPm = HH;
      this.toTime = HHdd;
      // 요일
      this.today = this.$moment().format('M월 DD일 dddd');
      // 한 시간 마다 날씨/미세먼지 조회
      if (this.$moment().format('mm:ss') === '00:00') {
        console.log('정각');
        this.getWeatherDust();
      }
    }, 1000);

    //날씨 최초 조회
    this.getWeatherDust();

    // console.log("ready...");
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    getWeatherDust() {
      this.getDust();
      this.getWeather();
    },
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log(window.innerWidth);

      this.commonInfo.height = this.scale * 150 + 'px';

      this.col.padding = this.scale * 30 + 'px';
      this.col.fontSize = this.scale * 45 + 'px';
      this.col.lineHeight = this.scale * 40 + 'px';

      this.span.fontSize = this.scale * 30 + 'px';

      this.dustIcon.width = this.scale * 35 + 'px';
    },
    async getDust() {
      const res = await fineDustQ(this.reqData);
      console.log(res);
      if (res.data && res.data.pm10Value) {
        this.dustVal = Number(res.data.pm10Value);
      }
    },
    async getWeather() {
      const res = await weatherQ(this.reqData);
      console.log(res);
      if (!(res.data && res.data.item && res.data.item[0] && res.data.item[5] && res.data.item[6])) return;
      this.tmpVal = res.data.item[0].fcstValue;
      const skyVal = Number(res.data.item[5].fcstValue);
      const ptyVal = Number(res.data.item[6].fcstValue);

      if (ptyVal > 0) {
        switch (ptyVal) {
          case 1:
            this.weatherTotal = '비';
            break;
          case 2:
            this.weatherTotal = '비/눈';
            break;
          case 3:
            this.weatherTotal = '눈';
            break;
          case 4:
            this.weatherTotal = '소나기';
            break;
        }
      } else {
        switch (skyVal) {
          case 1:
            this.weatherTotal = '맑음';
            break;
          case 3:
            this.weatherTotal = '구름많음';
            break;
          case 4:
            this.weatherTotal = '흐림';
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.common-info {
  color: #ffffff;
  height: 150px;
  .row {
    margin: 0;
  }
  .col {
    padding: 30px;
    font-size: 45px;
    line-height: 40px;
    span {
      font-size: 30px;
    }
    img {
      width: 35px;
      vertical-align: middle;
      margin: -5px 2px 0 5px;
    }
  }
  .weather {
    text-align: right;
  }
}
</style>