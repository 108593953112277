<template>
  <div class="police-content">
    <div v-for="(item, i) in contentList">
      <transition-group name="fade">
        <img v-if="active === i + 1" :key="i" :src="item.img" alt="" />
      </transition-group>
    </div>

    <!-- <transition name="fade">
      <img v-if="active === 2" src="@/assets/img/policecontent/content_sample2.jpg" alt="" />
    </transition> -->
    <!-- <img v-if="active === 3" src="@/assets/img/policecontent/content_sample3.jpg" alt="" /> -->
  </div>
</template>

<script>
import { contentQ } from '@/api/content';
export default {
  data() {
    return {
      timer: '',
      active: 1,
      contentList: [],
      reqData: {
        job: 'FQ',
        page: 0,
        npp: 0,
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
      },
    };
  },
  mounted() {
    this.timer = setInterval(this.loopContent, 15000);
    this.getContent();
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    clearInterval(this.timer);
  },
  methods: {
    loopContent() {
      if (this.active === this.contentList.length) {
        clearInterval(this.timer);
        // alert('정지');
        this.$emit('toSafetyFacilityInfo');
        return;
      }
      this.active++;
    },
    updateContentList(data) {
      if (data && data.length > 0) {
        this.contentList = data;
      } else {
        this.$emit('toSafetyFacilityInfo');
      }
    },
    async getContent() {
      // res[0] : return code, res[1] : return data
      const res = await contentQ(this.reqData);

      if (res && res[0] && res[0][0] && res[0][0].return_code === '100') {
        this.updateContentList(res[1]);
      } else {
        // 홍보 콘텐츠 없을 경우 : 안전시설 안내 화면으로 전환
        this.$emit('toSafetyFacilityInfo');
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/style';
.police-content {
  // position: relative;
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>