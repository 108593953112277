<template>
  <div class="radar">
    <div class="r1-wrap">
      <img class="r1" src="@/assets/img/map/radar.png" alt="" />
    </div>
    <div class="r2-wrap">
      <img class="r2" src="@/assets/img/map/radar.png" alt="" />
    </div>
    <div class="r3-wrap">
      <img class="r3" src="@/assets/img/map/radar.png" alt="" />
    </div>
    <div class="r4-wrap">
      <img class="r4" src="@/assets/img/map/radar.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.radar {
  position: relative;
  height: 100%;
  overflow: hidden;
  .r1-wrap {
    position: absolute;
    height: 100%;
    animation: xAxis 40s infinite cubic-bezier(0.6, 0.01, 0.41, 0.4);
  }
  .r2-wrap {
    position: absolute;
    left: 0;
    height: 100%;
    animation: xAxis2 25s infinite cubic-bezier(0.02, 0.31, 0.21, 0.2);
  }
  .r3-wrap {
    position: absolute;
    top: 0;
    // right: 200px;
    right: 400px;
    height: 100%;
    animation: xAxis3 30s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
  }
  .r4-wrap {
    position: absolute;
    bottom: 0;
    left: 340px;
    height: 100%;
    animation: xAxis4 30s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
  }
  img {
    position: absolute;
    &.r1 {
    //   bottom: 300px;
    //   width: 200px;
      bottom: 300px;
      width: 500px;
      animation: yAxis 35s infinite cubic-bezier(0.6, 0.31, 0.41, 0.1);
    }
    &.r2 {
    //   width: 150px;
      width: 400px;
      animation: yAxis2 20s infinite cubic-bezier(0.3, 0.27, 0.07, 0.64);
    }
    &.r3 {
      //   width: 200px;
      width: 400px;
      animation: yAxis3 25s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
    }
    &.r4 {
    //   width: 170px;
      width: 340px;
      animation: yAxis4 25s infinite cubic-bezier(0.32, 0, 0.67, 0);
    }
  }
}
@keyframes yAxis {
  50% {
    // animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
    transform: translateY(-300px);
  }
}

@keyframes xAxis {
  50% {
    // animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
    transform: translateX(300px);
  }
}

@keyframes yAxis2 {
  50% {
    // animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
    transform: translateY(300px);
  }
}

@keyframes xAxis2 {
  50% {
    // animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
    transform: translateX(300px);
  }
}

@keyframes yAxis3 {
  50% {
    // animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
    transform: translateY(200px);
  }
}

@keyframes xAxis3 {
  50% {
    // animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
    transform: translateX(-400px);
  }
}

@keyframes yAxis4 {
  50% {
    // animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
    // transform: translateY(300px);
    transform: translateY(600px);
  }
}

@keyframes xAxis4 {
  50% {
    // animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
    transform: translateX(400px);
  }
}
</style>