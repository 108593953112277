<template>
  <div class="focusarea-mark">
    <transition name="alert-in">
      <img v-bind:style="p01" v-if="animation >= 1" class="pin p01" src="@/assets/img/map/focus_street.png" alt="" />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animation: 0,
      scale: 0,
      p01: {
        width: '',
        top: '',
        left: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.timer = setInterval(this.fadeInPin, 100);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    fadeInPin() {
      if (this.animation === 4) {
        clearInterval(this.timer);
        // alert('정지');
        return;
      }
      this.animation++;
    },
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log(window.innerWidth);

      this.p01.width = this.scale * 605 + 'px';
      this.p01.top = this.scale * 198 + 'px';
      this.p01.left = this.scale * 198 + 'px';
    },
  },
};
</script>

<style lang="scss">
.focusarea-mark {
  .pin {
    position: absolute;
    width: 605px;
    &.p01 {
      top: 198px;
      left: 198px;
    }
  }
  .alert-in-enter-active {
    animation: fadeInFlash 2s;
  }
  .alert-in-leave-active {
    animation: fadeInFlash 2s reverse;
  }
  @keyframes fadeInFlash {
    0%,
    40%,
    80% {
      opacity: 0;
    }
    20%,
    60%,
    to {
      opacity: 1;
    }
  }
}
</style>