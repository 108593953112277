// board 
import { board } from './index';

const topBoardQ = (reqData) => {
    return board.get(`${reqData.job}?page=${reqData.page}&npp=${reqData.npp}&fromDay=${reqData.fromDay}&toDay=${reqData.toDay}&searchGbn=${reqData.searchGbn}&searchVal=${reqData.searchVal}`)
        .then(res => {
            return res.data[0];
        })
        .catch(err => {
            return err;
        });
}


export { topBoardQ };
