<template>
  <div class="sos">
    <Sosmark />
    <Sosbanner />
  </div>
</template>

<script>
import Sosmark from '@/components/main/safetyinfo/sos/SOS_Mark.vue';
import Sosbanner from '@/components/main/safetyinfo/sos/SOS_Banner.vue';
export default {
  components: {
    Sosmark,
    Sosbanner,
  },
};
</script>

<style>
</style>