<template>
  <div class="sos-mark">
    <transition name="alert-in">
      <img v-bind:style="p01" v-if="animation >= 1" class="pin p01" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p02" v-if="animation >= 2" class="pin p02" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p03" v-if="animation >= 3" class="pin p03" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p04" v-if="animation >= 4" class="pin p04" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p05" v-if="animation >= 5" class="pin p05" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p06" v-if="animation >= 6" class="pin p06" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p07" v-if="animation >= 7" class="pin p07" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p08" v-if="animation >= 8" class="pin p08" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p09" v-if="animation >= 9" class="pin p09" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p10" v-if="animation >= 10" class="pin p10" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p11" v-if="animation >= 11" class="pin p11" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p12" v-if="animation >= 12" class="pin p12" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p13" v-if="animation >= 13" class="pin p13" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p14" v-if="animation >= 14" class="pin p14" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p15" v-if="animation >= 15" class="pin p15" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p16" v-if="animation >= 16" class="pin p16" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p17" v-if="animation >= 17" class="pin p17" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p18" v-if="animation >= 18" class="pin p18" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p19" v-if="animation >= 19" class="pin p19" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p20" v-if="animation >= 20" class="pin p20" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p21" v-if="animation >= 21" class="pin p21" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p22" v-if="animation >= 22" class="pin p22" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p23" v-if="animation >= 23" class="pin p23" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p24" v-if="animation >= 24" class="pin p24" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p25" v-if="animation >= 25" class="pin p25" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p26" v-if="animation >= 26" class="pin p26" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p27" v-if="animation >= 27" class="pin p27" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p28" v-if="animation >= 28" class="pin p28" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p29" v-if="animation >= 29" class="pin p29" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p30" v-if="animation >= 30" class="pin p30" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
    <transition name="alert-in">
      <img v-bind:style="p31" v-if="animation >= 31" class="pin p31" src="@/assets/img/map/sos_pin.png" alt="" />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animation: 0,
      scale: 0,
      p01: {
        width: '',
        top: '',
        left: '',
      },
      p02: {
        width: '',
        top: '',
        left: '',
      },
      p03: {
        width: '',
        top: '',
        left: '',
      },
      p04: {
        width: '',
        top: '',
        left: '',
      },
      p05: {
        width: '',
        top: '',
        left: '',
      },
      p06: {
        width: '',
        top: '',
        left: '',
      },
      p07: {
        width: '',
        top: '',
        left: '',
      },
      p08: {
        width: '',
        top: '',
        left: '',
      },
      p09: {
        width: '',
        top: '',
        left: '',
      },
      p10: {
        width: '',
        top: '',
        left: '',
      },
      p11: {
        width: '',
        top: '',
        left: '',
      },
      p12: {
        width: '',
        top: '',
        left: '',
      },
      p13: {
        width: '',
        top: '',
        left: '',
      },
      p14: {
        width: '',
        top: '',
        left: '',
      },
      p15: {
        width: '',
        top: '',
        left: '',
      },
      p16: {
        width: '',
        top: '',
        left: '',
      },
      p17: {
        width: '',
        top: '',
        left: '',
      },
      p18: {
        width: '',
        top: '',
        left: '',
      },
      p19: {
        width: '',
        top: '',
        left: '',
      },
      p20: {
        width: '',
        top: '',
        left: '',
      },
      p21: {
        width: '',
        top: '',
        left: '',
      },
      p22: {
        width: '',
        top: '',
        left: '',
      },
      p22: {
        width: '',
        top: '',
        left: '',
      },
      p23: {
        width: '',
        top: '',
        left: '',
      },
      p24: {
        width: '',
        top: '',
        left: '',
      },
      p25: {
        width: '',
        top: '',
        left: '',
      },
      p26: {
        width: '',
        top: '',
        left: '',
      },
      p27: {
        width: '',
        top: '',
        left: '',
      },
      p28: {
        width: '',
        top: '',
        left: '',
      },
      p29: {
        width: '',
        top: '',
        left: '',
      },
      p30: {
        width: '',
        top: '',
        left: '',
      },
      p31: {
        width: '',
        top: '',
        left: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  mounted() {
    this.timer = setInterval(this.fadeInPin, 50);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    fadeInPin() {
      if (this.animation === 31) {
        clearInterval(this.timer);
        // alert('정지');
        return;
      }
      this.animation++;
    },
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log(window.innerWidth);

      this.p01.width = this.scale * 50 + 'px';
      this.p01.top = this.scale * 430 + 'px';
      this.p01.left = this.scale * 182 + 'px';

      this.p02.width = this.scale * 50 + 'px';
      this.p02.top = this.scale * 461 + 'px';
      this.p02.left = this.scale * 204 + 'px';

      this.p03.width = this.scale * 50 + 'px';
      this.p03.top = this.scale * 591 + 'px';
      this.p03.left = this.scale * 205 + 'px';

      this.p04.width = this.scale * 50 + 'px';
      this.p04.top = this.scale * 933 + 'px';
      this.p04.left = this.scale * 203 + 'px';

      this.p05.width = this.scale * 50 + 'px';
      this.p05.top = this.scale * 423 + 'px';
      this.p05.left = this.scale * 271 + 'px';

      this.p06.width = this.scale * 50 + 'px';
      this.p06.top = this.scale * 526 + 'px';
      this.p06.left = this.scale * 276 + 'px';

      this.p07.width = this.scale * 50 + 'px';
      this.p07.top = this.scale * 725 + 'px';
      this.p07.left = this.scale * 275 + 'px';

      this.p08.width = this.scale * 50 + 'px';
      this.p08.top = this.scale * 802 + 'px';
      this.p08.left = this.scale * 373 + 'px';

      this.p09.width = this.scale * 50 + 'px';
      this.p09.top = this.scale * 345 + 'px';
      this.p09.left = this.scale * 388 + 'px';

      this.p10.width = this.scale * 50 + 'px';
      this.p10.top = this.scale * 403 + 'px';
      this.p10.left = this.scale * 429 + 'px';

      this.p11.width = this.scale * 50 + 'px';
      this.p11.top = this.scale * 660 + 'px';
      this.p11.left = this.scale * 430 + 'px';

      this.p12.width = this.scale * 50 + 'px';
      this.p12.top = this.scale * 869 + 'px';
      this.p12.left = this.scale * 430 + 'px';

      this.p13.width = this.scale * 50 + 'px';
      this.p13.top = this.scale * 726 + 'px';
      this.p13.left = this.scale * 502 + 'px';

      this.p14.width = this.scale * 50 + 'px';
      this.p14.top = this.scale * 672 + 'px';
      this.p14.left = this.scale * 547 + 'px';

      this.p15.width = this.scale * 50 + 'px';
      this.p15.top = this.scale * 203 + 'px';
      this.p15.left = this.scale * 541 + 'px';

      this.p16.width = this.scale * 50 + 'px';
      this.p16.top = this.scale * 383 + 'px';
      this.p16.left = this.scale * 580 + 'px';

      this.p17.width = this.scale * 50 + 'px';
      this.p17.top = this.scale * 452 + 'px';
      this.p17.left = this.scale * 635 + 'px';

      this.p18.width = this.scale * 50 + 'px';
      this.p18.top = this.scale * 520 + 'px';
      this.p18.left = this.scale * 607 + 'px';

      this.p19.width = this.scale * 50 + 'px';
      this.p19.top = this.scale * 597 + 'px';
      this.p19.left = this.scale * 594 + 'px';

      this.p20.width = this.scale * 50 + 'px';
      this.p20.top = this.scale * 727 + 'px';
      this.p20.left = this.scale * 595 + 'px';

      this.p21.width = this.scale * 50 + 'px';
      this.p21.top = this.scale * 197 + 'px';
      this.p21.left = this.scale * 655 + 'px';

      this.p22.width = this.scale * 50 + 'px';
      this.p22.top = this.scale * 327 + 'px';
      this.p22.left = this.scale * 619 + 'px';

      this.p23.width = this.scale * 50 + 'px';
      this.p23.top = this.scale * 386 + 'px';
      this.p23.left = this.scale * 663 + 'px';

      this.p24.width = this.scale * 50 + 'px';
      this.p24.top = this.scale * 577 + 'px';
      this.p24.left = this.scale * 671 + 'px';

      this.p25.width = this.scale * 50 + 'px';
      this.p25.top = this.scale * 832 + 'px';
      this.p25.left = this.scale * 661 + 'px';

      this.p26.width = this.scale * 50 + 'px';
      this.p26.top = this.scale * 356 + 'px';
      this.p26.left = this.scale * 757 + 'px';

      this.p27.width = this.scale * 50 + 'px';
      this.p27.top = this.scale * 602 + 'px';
      this.p27.left = this.scale * 750 + 'px';

      this.p28.width = this.scale * 50 + 'px';
      this.p28.top = this.scale * 670 + 'px';
      this.p28.left = this.scale * 750 + 'px';

      this.p29.width = this.scale * 50 + 'px';
      this.p29.top = this.scale * 427 + 'px';
      this.p29.left = this.scale * 857 + 'px';

      this.p30.width = this.scale * 50 + 'px';
      this.p30.top = this.scale * 612 + 'px';
      this.p30.left = this.scale * 905 + 'px';

      this.p31.width = this.scale * 50 + 'px';
      this.p31.top = this.scale * 480 + 'px';
      this.p31.left = this.scale * 929 + 'px';
    },
  },
};
</script>

<style lang="scss">
.sos-mark {
  .pin {
    position: absolute;
    width: 50px;
    // opacity: 0;
    transition: 0.4s;
    // &.p01 {
    //   top: 200px;
    //   left: 540px;
    // }
    // &.p02 {
    //   top: 200px;
    //   left: 650px;
    // }
    &.p01 {
      top: 430px;
      left: 182px;
    }
    &.p02 {
      top: 461px;
      left: 204px;
    }
    &.p03 {
      top: 591px;
      left: 205px;
    }
    &.p04 {
      top: 933px;
      left: 203px;
    }
    &.p05 {
      top: 423px;
      left: 271px;
    }
    &.p06 {
      top: 526px;
      left: 276px;
    }
    &.p07 {
      top: 725px;
      left: 275px;
    }
    &.p08 {
      top: 802px;
      left: 373px;
    }
    &.p09 {
      top: 345px;
      left: 388px;
    }
    &.p10 {
      top: 403px;
      left: 429px;
    }
    &.p11 {
      top: 660px;
      left: 430px;
    }
    &.p12 {
      top: 869px;
      left: 430px;
    }
    &.p13 {
      top: 726px;
      left: 502px;
    }
    &.p14 {
      top: 672px;
      left: 547px;
    }

    &.p15 {
      top: 203px;
      left: 541px;
    }
    &.p16 {
      top: 383px;
      left: 580px;
    }
    &.p17 {
      top: 452px;
      left: 635px;
    }
    &.p18 {
      top: 520px;
      left: 607px;
    }
    &.p19 {
      top: 597px;
      left: 594px;
    }
    &.p20 {
      top: 727px;
      left: 595px;
    }
    &.p21 {
      top: 197px;
      left: 655px;
    }
    &.p22 {
      top: 327px;
      left: 619px;
    }
    &.p23 {
      top: 386px;
      left: 663px;
    }
    &.p24 {
      top: 577px;
      left: 671px;
    }
    &.p25 {
      top: 832px;
      left: 661px;
    }
    &.p26 {
      top: 356px;
      left: 757px;
    }
    &.p27 {
      top: 602px;
      left: 750px;
    }
    &.p28 {
      top: 670px;
      left: 750px;
    }
    &.p29 {
      top: 427px;
      left: 857px;
    }
    &.p30 {
      top: 612px;
      left: 905px;
    }
    &.p31 {
      top: 480px;
      left: 929px;
    }
  }
  .alert-in-enter-active {
    animation: bounce-in 0.5s;
  }
  .alert-in-leave-active {
    animation: bounce-in 0.5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>