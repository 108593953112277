<template>
  <div class="pa-description">
    <v-row v-bind:style="row">
      <v-col cols="1">
        <img v-bind:style="img" src="@/assets/img/map/icon_patrolarea_description.png" alt="" />
      </v-col>
      <v-col cols="8">
        <h3 v-bind:style="h3">집중 순찰거점 및 활동 현황</h3>
        <!-- <p v-bind:style="p">밝게 빛나며 이동하는 원은 경찰이 집중적으로 순찰하고 있는 곳 입니다.</p> -->
        <!-- <p v-bind:style="p">밝게 빛나는 원은 경찰이 집중적으로 순찰하고 있는 곳 입니다.</p> -->
      </v-col>
      <v-col cols="3">
        <img v-bind:style="img" class="patrolarea_QR" src="@/assets/img/map/icon_patrolarea_QR.png" alt="" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scale: 0,
      row: {
        paddingTop: '',
        paddingRight: '',
        paddingBottom: '',
        paddingLeft: '',
      },

      lastCol: {},
      img: {
        height: '',
      },
      h3: {
        fontSize: '',
      },
      p: {
        fontSize: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  mounted() {
    // console.log("ready...");
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log(window.innerWidth);

      this.row.paddingTop = this.scale * 10 + 'px';
      this.row.paddingRight = this.scale * 40 + 'px';
      this.row.paddingBottom = this.scale * 10 + 'px';
      this.row.paddingLeft = this.scale * 40 + 'px';

      // img
      this.img.height = this.scale * 75 + 'px';

      // h3
      this.h3.fontSize = this.scale * 30 + 'px';

      // p
      this.p.fontSize = this.scale * 20 + 'px';
    },
  },
};
</script>

<style lang="scss">
.pa-description {
  position: absolute;
  top: 0px;
  width: 100%;
  .row {
    padding: 10px 40px;
    align-items: center;
  }
  .col:last-child {
    padding-left: 30px;
  }
  img {
    height: 75px;
  }
  h3 {
    font-size: 30px;
    color: #abbfc4;
  }
  p {
    font-size: 20px;
    color: #abbfc4;
  }
}
</style>