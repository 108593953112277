<template>
  <div class="link-layer">
    <!-- BETADINE -->
    <div class="link-btn betadine-btn" v-bind:style="betadine" @click="PageTo($url_list.BETADINE)"></div>

    <!-- TACHOSIL -->
    <div class="link-btn tachosil-btn" v-bind:style="tachosil" @click="PageTo($url_list.TACHOSIL)"></div>

    <!-- MERONEM : Overall Wellness -->
    <div class="link-btn meronem-btn" v-bind:style="meronem" @click="PageTo($url_list.MERONEM)"></div>

    <!-- SEROQUEL : Mental Health -->
    <div class="link-btn seroquel-btn" v-bind:style="seroquel" @click="PageTo($url_list.SEROQUEL)"></div>

    <!-- FERINJECT : Women’s health -->
    <div class="link-btn ferinject-btn" v-bind:style="ferinject" @click="PageTo($url_list.FERINJECT)"></div>

    <!-- Intro Video -->
    <div class="link-btn intro-btn" v-bind:style="intro" @click="contentPopupEmit('vod', '646244161')">
      <div class="flag intro" v-bind:style="introEffect">
        <h4>ZPT Brand INTRO & REVEAL</h4>
      </div>
    </div>

    <!-- CONTACTUS -->
    <div class="link-btn contactus-btn" v-bind:style="contactus" @click="msgBoardPopupEmit('lobby')">
      <!-- <div class="flag contactus" v-bind:style="contactusEffect">
        <h4>May I help you?</h4>
      </div> -->
    </div>

    <!-- Corporate Website -->
    <div class="thumb corporate-btn ani" v-bind:style="corporate">
      <a href="https://www.zuelligpharma.com/" target="(name)">
        <div class="shine" v-bind:style="corporateShine"></div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scale: 0,
      betadine: {
        top: '',
        left: '',
        width: '',
        height: '',
      },
      tachosil: {
        top: '',
        left: '',
        width: '',
        height: '',
      },
      meronem: {
        top: '',
        right: '',
        width: '',
        height: '',
      },
      seroquel: {
        top: '',
        right: '',
        width: '',
        height: '',
      },
      ferinject: {
        top: '',
        right: '',
        width: '',
        height: '',
      },

      contactus: {
        top: '',
        left: '',
        width: '',
        height: '',
      },
      corporate: {
        top: '',
        left: '',
        width: '',
        height: '',
      },
      intro: {
        top: '',
        left: '',
        width: '',
        height: '',
      },

      introEffect: {
        top: '',
        left: '',
        width: '',
        height: '',
      },
      contactusEffect: {
        top: '',
        right: '',
        width: '',
        height: '',
      },

      corporateShine: {
        top: '',
        left: '',
        width: '',
        height: '',
      },
    };
  },
  created() {
    this.handleResize();
    if (window.innerWidth < 768) {
      this.handleResizeMobile();
      this.$emit('deviceType', 'mobile');
    } else {
      this.$emit('deviceType', 'pc');
    }
  },
  mounted() {
    // console.log("ready...");
    window.addEventListener('resize', this.resizeEvent);
  },
  methods: {
    PageTo(url) {
      this.$router.push(url);
    },
    beforeDestroy() {
      // console.log("beforeDestroy...");
      window.removeEventListener('resize', this.resizeEvent);
    },
    contentPopupEmit(type, url) {
      // console.log('Popup');
      const data = this.$emit('contentPopup', type, url);
    },
    msgBoardPopupEmit(brand) {
      // console.log('Popup');
      this.$emit('msgBoardPopup', brand);
    },
    resizeEvent() {
      // 화면사이즈에 따라 모바일용/PC용으로 분기
      if (window.innerWidth < 768) {
        this.handleResizeMobile();
        this.$emit('deviceType', 'mobile');
        // console.log('mobile');
      } else {
        this.$emit('deviceType', 'pc');
        this.handleResize();
        // console.log('pc');
      }
    },
    handleResize() {
      this.scale = window.innerWidth / 1920;
      // console.log(window.innerWidth);

      // Family Health & Self Care
      this.betadine.top = this.scale * 420 + 'px';
      this.betadine.left = this.scale * 14 + 'px';
      this.betadine.width = this.scale * 285 + 'px';
      this.betadine.height = this.scale * 294 + 'px';

      // Surgery & Patient Recover
      this.tachosil.top = this.scale * 420 + 'px';
      this.tachosil.left = this.scale * 315 + 'px';
      this.tachosil.width = this.scale * 240 + 'px';
      this.tachosil.height = this.scale * 294 + 'px';

      // TBD
      // this.betadine.top = this.scale * 420 + 'px';
      // this.betadine.left = this.scale * 800 + 'px';
      // this.betadine.width = this.scale * 235 + 'px';
      // this.betadine.height = this.scale * 294 + 'px';

      // meronem : Overall Wellness
      this.meronem.top = this.scale * 420 + 'px';
      this.meronem.right = this.scale * 885 + 'px';
      this.meronem.width = this.scale * 225 + 'px';
      this.meronem.height = this.scale * 294 + 'px';

      // seroquel : Mental Health
      this.seroquel.top = this.scale * 420 + 'px';
      this.seroquel.right = this.scale * 645 + 'px';
      this.seroquel.width = this.scale * 225 + 'px';
      this.seroquel.height = this.scale * 294 + 'px';

      // ferinject : Women’s health
      this.ferinject.top = this.scale * 420 + 'px';
      this.ferinject.right = this.scale * 355 + 'px';
      this.ferinject.width = this.scale * 285 + 'px';
      this.ferinject.height = this.scale * 294 + 'px';

      // contactus
      this.contactus.top = this.scale * 500 + 'px';
      this.contactus.left = this.scale * 555 + 'px';
      this.contactus.width = this.scale * 250 + 'px';
      this.contactus.height = this.scale * 200 + 'px';

      // corporate
      this.corporate.top = this.scale * 172 + 'px';
      this.corporate.left = this.scale * 954 + 'px';
      this.corporate.width = this.scale * 188 + 'px';
      this.corporate.height = this.scale * 95 + 'px';

      // intro
      this.intro.top = this.scale * 250 + 'px';
      this.intro.left = this.scale * 585 + 'px';
      this.intro.width = this.scale * 268 + 'px';
      this.intro.height = this.scale * 155 + 'px';

      // intro flag
      this.introEffect.top = this.scale * 50 + 'px';
      this.introEffect.left = this.scale * 270 + 'px';
      this.introEffect.width = this.scale * 380 + 'px';
      this.introEffect.height = this.scale * 100 + 'px';

      // contactus flag
      this.contactusEffect.top = this.scale * 40 + 'px';
      this.contactusEffect.right = this.scale * 130 + 'px';
      this.contactusEffect.width = this.scale * 380 + 'px';
      this.contactusEffect.height = this.scale * 100 + 'px';

      // corporate Shine Effect
      this.corporateShine.top = this.scale * 0 + 'px';
      this.corporateShine.left = this.scale * -20 + 'px';
      this.corporateShine.width = this.scale * 220 + 'px';
      this.corporateShine.height = this.scale * 95 + 'px';
    },
    handleResizeMobile() {
      this.scale = window.innerWidth / 750;
      // console.log(window.innerWidth);

      // Family Health & Self Care
      this.betadine.top = this.scale * 180 + 'px';
      this.betadine.left = this.scale * 400 + 'px';
      this.betadine.width = this.scale * 335 + 'px';
      this.betadine.height = this.scale * 160 + 'px';

      // Surgery & Patient Recover
      this.tachosil.top = this.scale * 347 + 'px';
      this.tachosil.left = this.scale * 400 + 'px';
      this.tachosil.width = this.scale * 335 + 'px';
      this.tachosil.height = this.scale * 160 + 'px';

      // TBD
      // this.betadine.top = this.scale * 420 + 'px';
      // this.betadine.left = this.scale * 800 + 'px';
      // this.betadine.width = this.scale * 235 + 'px';
      // this.betadine.height = this.scale * 294 + 'px';

      // meronem : Overall Wellness
      this.meronem.top = this.scale * 515 + 'px';
      this.meronem.right = this.scale * 0 + 'px';
      this.meronem.width = this.scale * 335 + 'px';
      this.meronem.height = this.scale * 160 + 'px';

      // seroquel : Mental Health
      this.seroquel.top = this.scale * 685 + 'px';
      this.seroquel.right = this.scale * 0 + 'px';
      this.seroquel.width = this.scale * 335 + 'px';
      this.seroquel.height = this.scale * 160 + 'px';

      // ferinject : Women’s health
      this.ferinject.top = this.scale * 860 + 'px';
      this.ferinject.right = this.scale * 0 + 'px';
      this.ferinject.width = this.scale * 335 + 'px';
      this.ferinject.height = this.scale * 160 + 'px';

      // contactus
      this.contactus.top = this.scale * 800 + 'px';
      this.contactus.left = this.scale * 110 + 'px';
      this.contactus.width = this.scale * 280 + 'px';
      this.contactus.height = this.scale * 230 + 'px';

      // corporate
      this.corporate.top = this.scale * 1122 + 'px';
      this.corporate.left = this.scale * 70 + 'px';
      this.corporate.width = this.scale * 150 + 'px';
      this.corporate.height = this.scale * 95 + 'px';

      // intro
      this.intro.top = this.scale * 640 + 'px';
      this.intro.left = this.scale * 45 + 'px';
      this.intro.width = this.scale * 220 + 'px';
      this.intro.height = this.scale * 135 + 'px';

      // intro flag
      this.introEffect.top = this.scale * 50 + 'px';
      this.introEffect.left = this.scale * 270 + 'px';
      this.introEffect.width = this.scale * 380 + 'px';
      this.introEffect.height = this.scale * 100 + 'px';

      // contactus flag
      this.contactusEffect.top = this.scale * 40 + 'px';
      this.contactusEffect.right = this.scale * 130 + 'px';
      this.contactusEffect.width = this.scale * 380 + 'px';
      this.contactusEffect.height = this.scale * 100 + 'px';

      // corporate Shine Effect
      this.corporateShine.top = this.scale * 0 + 'px';
      this.corporateShine.left = this.scale * -20 + 'px';
      this.corporateShine.width = this.scale * 220 + 'px';
      this.corporateShine.height = this.scale * 95 + 'px';
    },
  },
};
</script>

<style lang="scss" scoped>
.corporate-btn {
  background: url(~@/assets/img/ZP_logo.png) no-repeat;
  background-size: 100%;
  background-position: center;
}
</style>