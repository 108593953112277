<template>
  <div class="focusarea-banner">
    <transition name="slide-fade">
      <div v-bind:style="banner" class="banner" v-if="show">
        <v-row>
          <v-col cols="4" class="img-area">
            <transition name="slide-fade">
              <img v-bind:style="img" v-if="imgShow" src="@/assets/img/map/focus_img.png" alt="" />
            </transition>
          </v-col>
          <v-col cols="8" class="text-area" v-bind:style="textArea">
            <h3 v-bind:style="h3">집중 관찰 구간</h3>
            <p v-bind:style="p">
              PM 10:00 ~ AM 00:00<br />
              중랑구 CCTV종합관제 센터에서<br />
              집중 감시를 실시합니다.<br />
            </p>
          </v-col>
        </v-row>
        <div v-bind:style="colorBar" class="color-bar"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      imgShow: false,
      scale: 0,
      banner: {
        height: '',
      },
      img: {
        height: '',
      },
      textArea: {
        paddingTop: '',
        paddingRight: '',
        paddingBottom: '',
        paddingLeft: '',
      },
      h3: {
        fontSize: '',
      },
      p: {
        fontSize: '',
        paddingTop: '',
      },
      colorBar: {
        height: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    setTimeout(() => {
      this.show = true;

      setTimeout(() => {
        this.imgShow = true;
      }, 240);
    }, 200);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log('ready...');

      this.banner.height = this.scale * 330 + 'px';

      this.img.height = this.scale * 311 + 'px';

      this.textArea.paddingTop = this.scale * 40 + 'px';
      this.textArea.paddingRight = this.scale * 20 + 'px';
      this.textArea.paddingBottom = this.scale * 40 + 'px';
      this.textArea.paddingLeft = this.scale * 20 + 'px';

      this.h3.fontSize = this.scale * 40 + 'px';

      this.p.fontSize = this.scale * 35 + 'px';
      this.p.paddingTop = this.scale * 10 + 'px';

      this.colorBar.height = this.scale * 20 + 'px';
    },
  },
};
</script>

<style lang="scss">
.focusarea-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .banner {
    width: 100%;
    height: 330px;
    background-color: #15353f;
    .row {
      margin: 0;
      padding: 0;
      .col {
        margin: 0;
      }
    }
  }
  .img-area {
    // background-color: #e79b24;
    position: relative;
    text-align: center;

    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 500;
      height: 311px;
    }
  }
  .text-area {
    // padding: 40px 0;
    padding: 40px 20px 40px 20px;
    color: #ffffff;
    h3 {
      font-size: 40px;
      .new-tag {
        display: inline-block;
        padding: 1px 12px;
        font-size: 20px;
        background: #b2cc56;
        border-radius: 20px;
        font-weight: 900;
        color: #444d16;
        vertical-align: middle;
        margin: -10px 10px 0;
      }
    }
    p {
      font-size: 35px;
      padding: 10px 0;
      color: #c0cacc;
    }
  }
  .color-bar {
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    background-color: #6dc4de;
  }
  .slide-fade-enter-active {
    transition: all 0.8s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(20px);
    opacity: 0;
  }
}
</style>