<template>
  <div class="main page">
    <!-- <LoadingSpinner v-if="isLoading"></LoadingSpinner> -->
    <!-- <Menu/> -->

    <!-- 일반 정보 -->
    <CommonInfo />

    <!-- 전광판 -->
    <TopBoard />

    <!-- 안전시설정보 -->
    <transition name="fade">
      <Safetyinfo v-if="Safetyinfo" @toPoliceContent="switchPage" />
    </transition>

    <!-- 경찰 홍보컨텐츠 -->
    <transition name="fade">
      <Policecontent v-if="!Safetyinfo" @toSafetyFacilityInfo="switchPage" />
    </transition>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import Menu from '@/components/common/Menu.vue';
import Linklayer from '@/components/main/LinkLayer_Main.vue';
import CommonInfo from '@/components/main/CommonInfo.vue';
import TopBoard from '@/components/main/TopBoard.vue';
import Safetyinfo from '@/components/main/safetyinfo/SafetyFacilityInfo_Start.vue';
import Policecontent from '@/components/main/policecontent/PoliceContent_Start.vue';

export default {
  components: {
    LoadingSpinner,
    Menu,
    Linklayer,
    CommonInfo,
    TopBoard,
    Safetyinfo,
    Policecontent,
  },
  data() {
    return {
      isLoading: false,
      Safetyinfo: true,
      // Safetyinfo: false,
    };
  },
  methods: {
    switchPage() {
      this.Safetyinfo = !this.Safetyinfo;
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/style';
.page {
  background: #090d10;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
