<template>
  <div class="smartlight-banner">
    <transition name="slide-fade">
      <div v-bind:style="banner" class="banner" v-if="show">
        <v-row>
          <v-col cols="4" class="img-area">
            <transition name="slide-fade">
              <img v-bind:style="img" v-if="imgShow" src="@/assets/img/map/smartlight_img.png" alt="" />
            </transition>
          </v-col>
          <v-col cols="6" class="text-area" v-bind:style="textArea">
            <h3 v-bind:style="h3">스마트 보안등<span v-bind:style="newTag" class="new-tag">NEW</span></h3>
            <p v-bind:style="p">
              안심이앱 이용 중 긴급신고<br />
              (휴대폰 흔들기)시 보안등이 깜박이며 <br />
              관제센터와 연결됩니다.
            </p>
          </v-col>
          <v-col cols="2" class="text-area">
            <img v-bind:style="imgQR" class="QR" src="@/assets/img/map/smartlight_QR.png" alt="" />
          </v-col>
        </v-row>
        <div v-bind:style="colorBar" class="color-bar"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      imgShow: false,
      scale: 0,
      banner: {
        height: '',
      },
      img: {
        height: '',
      },
      textArea: {
        paddingTop: '',
        paddingRight: '',
        paddingBottom: '',
        paddingLeft: '',
      },
      newTag: {
        fontSize: '',
        paddingTop: '',
        paddingRight: '',
        paddingBottom: '',
        paddingLeft: '',
        borderRadius: '',
        marginTop: '',
        marginRight: '',
        marginBottom: '',
        marginLeft: '',
      },
      h3: {
        fontSize: '',
      },
      p: {
        fontSize: '',
        paddingTop: '',
      },
      colorBar: {
        height: '',
      },

      imgQR: {
        width: '',
        marginTop: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    setTimeout(() => {
      this.show = true;

      setTimeout(() => {
        this.imgShow = true;
      }, 240);
    }, 200);
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log('ready...');

      this.banner.height = this.scale * 330 + 'px';

      this.img.height = this.scale * 311 + 'px';

      this.textArea.paddingTop = this.scale * 40 + 'px';
      this.textArea.paddingRight = this.scale * 20 + 'px';
      this.textArea.paddingBottom = this.scale * 40 + 'px';
      this.textArea.paddingLeft = this.scale * 20 + 'px';

      this.newTag.fontSize = this.scale * 20 + 'px';
      this.newTag.paddingTop = this.scale * 1 + 'px';
      this.newTag.paddingRight = this.scale * 12 + 'px';
      this.newTag.paddingBottom = this.scale * 1 + 'px';
      this.newTag.paddingLeft = this.scale * 12 + 'px';
      this.newTag.borderRadius = this.scale * 20 + 'px';
      this.newTag.marginTop = this.scale * -10 + 'px';
      this.newTag.marginRight = this.scale * 10 + 'px';
      this.newTag.marginBottom = this.scale * 0 + 'px';
      this.newTag.marginLeft = this.scale * 10 + 'px';

      this.h3.fontSize = this.scale * 40 + 'px';

      this.p.fontSize = this.scale * 32 + 'px';
      this.p.paddingTop = this.scale * 10 + 'px';

      this.colorBar.height = this.scale * 20 + 'px';

      this.imgQR.width = this.scale * 120 + 'px';
      this.imgQR.marginTop = this.scale * 35 + 'px';
    },
  },
};
</script>

<style lang="scss">
.smartlight-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .banner {
    position: relative;
    width: 100%;
    height: 330px;
    background-color: #0b3f2b;
    .row {
      margin: 0;
      padding: 0;
      .col {
        margin: 0;
      }
    }
  }
  .img-area {
    // background-color: #e79b24;
    // position: relative;
    text-align: center;

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 500;
      height: 311px;
    }
  }
  .text-area {
    // padding: 40px 0;
    padding: 40px 20px 40px 20px;
    color: #ffffff;
    h3 {
      font-size: 40px;
      .new-tag {
        display: inline-block;
        padding: 1px 12px;
        font-size: 20px;
        background: #20b785;
        border-radius: 20px;
        font-weight: 900;
        color: #15353f;
        vertical-align: middle;
        margin: -10px 10px 0;
      }
    }
    p {
      font-size: 32px;
      padding: 10px 0;
      color: #c0cacc;
      // word-spacing: -10px;
    }
    img.QR {
      margin-top: 35px;
      width: 120px;
    }
  }
  .color-bar {
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    background-color: #20b785;
  }
  .slide-fade-enter-active {
    transition: all 0.8s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
  }
}
</style>