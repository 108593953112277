<template>
  <div class="top-board" v-bind:style="topBoard">
    <img class="board-filter" src="@/assets/img/top_board/board_filter.png" alt="" />
    <marquee direction="left" ref="marquee" scrollamount="15" v-bind:style="marquee">{{ loopText }}</marquee>
  </div>
</template>

<script>
import { topBoardQ } from '@/api/board';

export default {
  data() {
    return {
      timer: null,
      scale: 0,
      topBoard: {
        height: '',
      },
      marquee: {
        fontSize: '',
        lineHeight: '',
      },
      loopText: '',
      reqData: {
        job: 'FQ',
        page: 0,
        npp: 0,
        fromDay: '',
        toDay: '',
        searchGbn: '',
        searchVal: '',
      },
    };
  },
  created() {
    this.handleResize();
  },
  mounted() {
    // console.log("ready...");
    const self = this;
    window.addEventListener('offline', () => {
      self.loopText = '시스템 점검중입니다.';
    });
    window.addEventListener('resize', this.handleResize);
    this.timer = setInterval(this.getText, 15 * 1000);
    this.getText();
  },
  beforeDestroy() {
    // console.log("beforeDestroy...");
    clearInterval(this.timer);
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.scale = window.innerWidth / 1080;

      // console.log(window.innerWidth);

      //topBoard
      this.topBoard.height = this.scale * 200 + 'px';

      // marquee
      this.marquee.fontSize = this.scale * 140 + 'px';
      this.marquee.lineHeight = this.scale * 200 + 'px';
    },
    async getText() {
      console.log('getText');
      const res = await topBoardQ(this.reqData);
      // console.log(res);
      if (res && res[0]) {
        this.loopText = res[0].notice;
        // this.$refs.marquee.width
        if (res[0].return_code !== '100') {
          this.loopText = '전광판 점검중입니다.';
        }
      }
    },
  },
};
</script>

<style lang="scss">
.top-board {
  position: relative;
  height: 200px;
  width: 100%;
  .board-filter {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 500;
    // box-shadow: 0 60px 90px 40px #090d10;
  }
  marquee {
    font-size: 140px;
    line-height: 200px;
    font-weight: 700;
    color: #cdd950;
  }
}
</style>