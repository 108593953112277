<template>
  <div class="smart">
    <Smartmark />
    <Smartbanner />
  </div>
</template>

<script>
import Smartmark from '@/components/main/safetyinfo/smartlight/SmartLight_Mark.vue';
import Smartbanner from '@/components/main/safetyinfo/smartlight/SmartLight_Banner.vue';
export default {
  components: {
    Smartmark,
    Smartbanner,
  },
};
</script>

<style>
</style>