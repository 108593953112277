// 미세먼지 
import { weather } from './index';

const weatherQ = (reqData) => {
    return weather.get(`weather`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        });
}


export { weatherQ };
