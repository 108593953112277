<template>
  <div class="safe">
    <Sbmark />
    <Sbbanner />
  </div>
</template>

<script>
import Sbmark from '@/components/main/safetyinfo/safetyboard/SafetyBoard_Mark.vue';
import Sbbanner from '@/components/main/safetyinfo/safetyboard/SafetyBoard_Banner.vue';
export default {
  components: {
    Sbmark,
    Sbbanner,
  },
};
</script>

<style>
</style>