<template>
  <div class="safe">
    <Safemark />
    <Safebanner />
  </div>
</template>

<script>
import Safemark from '@/components/main/safetyinfo/safewayhome/SafeWayhome_Mark.vue';
import Safebanner from '@/components/main/safetyinfo/safewayhome/SafeWayhome_Banner.vue';
export default {
  components: {
    Safemark,
    Safebanner,
  },
};
</script>

<style>
</style>